.im-w-full {
  width: 100% !important;
}

.user-avatar{
  -webkit-clip-path: url(#icons_squircle-96px);
  clip-path: url(#icons_squircle-96px);
  border-radius: 28px;
}

/* .category_img_shadow {
  -webkit-filter: drop-shadow(0px 0px 7px rgba(0, 255, 0, 0.65));
  filter: drop-shadow(0px 0px 7px rgba(0, 255, 0, 0.65));
} */

.step-confirmation-w .confirmation-number {
  color: #8e97b3;
  padding-top: 0px;
  margin-bottom: 8px;
  font-size: 25px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-weight: 500;
  border-bottom: 2px dashed #e2e8f0;
  padding-bottom: 8px;
}

.step-confirmation-w .confirmation-cabinet-info {
  background-color: #f5f6ff;
  margin-top: 20px;
  text-align: center;
  padding: 20px 40px;
  display: none;
}

.step-confirmation-w .confirmation-cabinet-info .confirmation-cabinet-text {
  color: #14161d;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
}

.step-confirmation-w .confirmation-cabinet-info .confirmation-cabinet-link {
  font-size: 14px;
  background-color: #1da1f2;
  color: #fff !important;
  padding: 8px 15px !important;
  font-weight: 500;
  text-decoration: none !important;
  border: none !important;
  display: inline-block !important;
}
.confirmation-info-w {
  margin-top: 20px;
}
.confirmation-info-w ul {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-wrap: wrap;
}
.confirmation-info-w ul li {
  flex: 0 0 50%;
  list-style-type: none;
  margin: 0px;
  color: #8e97b3;
  font-weight: 500;
  padding: 7px 10px 7px 0px;
  line-height: 1.4;
  font-size: 10px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
}
.confirmation-info-w ul li strong {
  text-transform: none;
  letter-spacing: normal;
  color: #1f222b;
  display: block;
  font-size: 14px;
}
.confirmation-info-w .confirmation-section-heading {
  font-size: 18px;
  display: block;
  border-bottom: 1px solid #1da1f2;
  margin: 15px 0px 5px 0px;
  color: #1da1f2;
  padding-bottom: 5px;
}

.react-add-to-calendar {
  position: relative;
  z-index: 2;
}
.add-to-calendar-dropdown {
  top: -185px;
}
.add-to-calendar-dropdown li {
  color: #000;
  padding: 3px 15px;
  /* border-bottom: 1px solid #e8e8e8; */
}

.add-to-calendar-dropdown li:not(:last-of-type) {
  border-bottom: 1px solid #e8e8e8;
}
.add-to-calendar-dropdown li:hover {
  color: #1da1f2;
}

.add-to-calendar-dropdown::before {
  content: "";
  bottom: -20px;
  left: 50px;
  width: 10px;
  height: 10px;
  position: absolute;
  z-index: -1;
  border-style: solid;
  border-width: 10px;
  border-color: #fff transparent transparent;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.35));
}


/* ************ Join as a consulter page ************ */

.banner{
  height: 600px;
  height: 100vh;
  width: 100%;
  background-image: url('/images/mostachar-banner.png');
  background-position: 15% 30%;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  padding-bottom: 10em;
}


.howItWorksBgImg{
  background: url("/images/getstarted2.png");
  background-position: top;
  background-repeat: no-repeat;
  left: 30%;
  bottom: -5%;
  /* background-size: cover; */
}

.getStartedImgBg{
  background: url("/images/getstarted3.png");
  background-position: top;
  background-repeat: no-repeat;
  left: 0%;
  /* background-size: cover; */
}

/* ********** Consulter profile ********** */
.profileBg{
  background: #0093E9;
  background-image: linear-gradient(to top, #005bea 0%, #04befe 100%);
}

.activeConsulterTab{position: relative;}
.activeConsulterTab.answers{ border-radius: 0 0.5rem 0 0 !important; }
.activeConsulterTab.reviews{ border-radius: 0.5rem 0 0 0 !important; }

.activeConsulterTab::before{
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: #03A9F4;
  background-image: linear-gradient(to right, #005bea 0%, #04befe 100%);
}


/* ******* Rating stars ******* */

.rating {height: 150px;}

.rating-0 {
  filter: grayscale(100%);
}

.rating > input {
  display: none;
}

.rating > label {
  cursor: pointer;
  width: 40px;
  height: 40px;
  margin-top: auto;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23e3e3e3' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 76%;
  transition: .3s;
}
/* FFC107 */
.rating > input:checked ~ label,
.rating > input:checked ~ label ~ label {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23FFC107' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
}


.rating > input:not(:checked) ~ label:hover,
.rating > input:not(:checked) ~ label:hover ~ label {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23FF9800' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
}

.emoji-wrapper {
  width: 100%;
  text-align: center;
  height: 100px;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.emoji-wrapper:before,
.emoji-wrapper:after{
  content: "";
  height: 15px;
  width: 100%;
  position: absolute;
  left: 0;
  z-index: 1;
}

.emoji-wrapper:before {
  top: 0;
  background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 35%,rgba(255,255,255,0) 100%);
}

.emoji-wrapper:after{
  bottom: 0;
  background: linear-gradient(to top, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 35%,rgba(255,255,255,0) 100%);
}

.emoji {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: .3s;
}

.emoji > svg {
  margin: 15px 0; 
  width: 70px;
  height: 70px;
  flex-shrink: 0;
}

#rating-1:checked ~ .emoji-wrapper > .emoji { transform: translateY(-100px); }
#rating-2:checked ~ .emoji-wrapper > .emoji { transform: translateY(-200px); }
#rating-3:checked ~ .emoji-wrapper > .emoji { transform: translateY(-300px); }
#rating-4:checked ~ .emoji-wrapper > .emoji { transform: translateY(-400px); }
#rating-5:checked ~ .emoji-wrapper > .emoji { transform: translateY(-500px); }

.feedback {
  background-color: #fff;
  width: 100%;
  padding: 30px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  box-shadow: 0 4px 30px rgba(0,0,0,.05);
}


/* *********** Explore page ************ */
/* .react-multi-carousel-list{padding-top: 10px; padding-bottom: 10px;} */

.customCarouselArrow.react-multiple-carousel__arrow--right::before,
.customCarouselArrow.react-multiple-carousel__arrow--left::before{content: '' !important;}

.explore-categories-bg{
  background-color: #2948ff;
  background-image: radial-gradient(at 50% 100%, #03a9f4, #2948ff);
}


.nice_btn_bg{
  /* background: linear-gradient(#03A9F4, #2196F3) #00BCD4; */
  background: linear-gradient(#03A9F4, #2948ff) #2196F3;
}

.nice_btn_bg:hover{
  background: #03A9F4;
}


/* ############## Custm Hyperpay forms ############### */
.wpwl-form{
  margin: 30px auto;
}

.wpwl-group{ 
  margin: 10px 0;
  /* direction: ltr; */
}

.wpwl-label{
  font-weight: bold;
  color: #555
}

.wpwl-hint{font-size: 12px !important;}

.wpwl-container-card .wpwl-control-brand{display: none}

.wpwl-group-cardNumber{
  display: block !important;
  width: 100% !important;
  padding-left: 0 !important;
}
.wpwl-group-cardHolder {
  display: block !important;
  width: 100% !important;
  padding-left: 0 !important;
}

.wpwl-group-cvv,
.wpwl-group-expiry{ 
  display: inline-block !important;
  width: 50% !important;
  padding: 1px;
}

.wpwl-control{
  border: 2px solid rgb(192, 192, 192);
  padding-left: 10px !important;
  /* direction: ltr; */
}

.wpwl-group-expiry{padding-left: 3px !important; }
.wpwl-group-cvv{padding-right: 3px !important;}


.wpwl-button-pay{ width: 100%; display: block}

/* .wpwl-group-brand {
  visibility: hidden;
} */

.wpwl-form-virtualAccount-STC_PAY,
.wpwl-form-card{
  background: none;
  border: none;
  box-shadow: none !important;
  border: 1px solid rgba(0, 0, 0, 0.095) !important
}

.wpwl-container-virtualAccount-PAYPAL{ text-align: center; }


/* --- paypal pay btn --- */
.wpwl-button .wpwl-button-brand{min-width: 100% !important;}
